<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.6397 11.3498C4.56137 12.4282 3.95557 13.8907 3.95557 15.4157C3.95557 16.9407 4.56137 18.4032 5.6397 19.4816C6.71804 20.5599 8.18057 21.1657 9.70557 21.1657C11.2306 21.1657 12.6931 20.5599 13.7714 19.4816L20.6657 12.5873L19.2515 11.1731L12.3572 18.0674C11.654 18.7706 10.7001 19.1657 9.70557 19.1657C8.711 19.1657 7.75718 18.7706 7.05392 18.0674C6.35065 17.3641 5.95557 16.4103 5.95557 15.4157C5.95557 14.4211 6.35065 13.4673 7.05392 12.7641L14.4785 5.33944C14.6875 5.13051 14.9355 4.96477 15.2085 4.8517C15.4815 4.73863 15.7741 4.68043 16.0695 4.68043C16.365 4.68043 16.6576 4.73863 16.9306 4.8517C17.2035 4.96477 17.4516 5.13051 17.6605 5.33944C17.8694 5.54837 18.0352 5.79641 18.1483 6.06939C18.2613 6.34237 18.3195 6.63495 18.3195 6.93043C18.3195 7.2259 18.2613 7.51848 18.1483 7.79147C18.0352 8.06445 17.8694 8.31249 17.6605 8.52142L11.6501 14.5318C11.5095 14.6725 11.3187 14.7515 11.1198 14.7515C10.9209 14.7515 10.7301 14.6725 10.5894 14.5318C10.4488 14.3912 10.3698 14.2004 10.3698 14.0015C10.3698 13.8026 10.4488 13.6118 10.5894 13.4712L16.0695 7.99109L14.6553 6.57688L9.17524 12.057C8.65951 12.5727 8.36978 13.2722 8.36978 14.0015C8.36978 14.7308 8.65951 15.4303 9.17524 15.946C9.69096 16.4618 10.3904 16.7515 11.1198 16.7515C11.8491 16.7515 12.5486 16.4618 13.0643 15.946L19.0747 9.93563C19.8718 9.1386 20.3195 8.0576 20.3195 6.93043C20.3195 5.80326 19.8718 4.72225 19.0747 3.92522C18.2777 3.12819 17.1967 2.68043 16.0695 2.68043C14.9424 2.68043 13.8614 3.12819 13.0643 3.92522L5.6397 11.3498Z"
                :fill="color"
            />
            <!-- eslint-enable max-len -->
        </template>
    </AppIcon>
</template>
